body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

/* Heatmap Container */
.heatmap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Preenche toda a largura do mapa */
  height: 100%; /* Preenche toda a altura do mapa */
  z-index: 450; /* Garante que o heatmap esteja sobre o mapa, mas abaixo de outros elementos */
  pointer-events: none; /* Permite interagir com o mapa sem interferência */
}

/* Ajuste para Ícones e Camadas do Mapa (se necessário no heatmap) */
.leaflet-div-icon {
  background: transparent !important; /* Sem fundo para os ícones */
  border: none !important; /* Remove bordas para evitar interferência */
}

/* Suavização do heatmap */
.leaflet-heatmap-layer {
  opacity: 0.8; /* Define a opacidade geral do heatmap */
  mix-blend-mode: multiply; /* Mistura o heatmap com o mapa para um visual mais natural */
}

.logo {
  height: 30px !important;
  width: auto !important;
  max-width: 120px !important;
  object-fit: contain !important;
  display: block !important;
}

